<template>
  <div>
    <!-- 顶部tab栏 -->
    <div class="headers">
      <ul>
        <li
          :class="{ selected: tabs == index }"
          v-for="(item, index) in tab"
          :key="index"
          @click="toggletabs(index, item.tabIndex)"
        >
          {{ item.name }}
        </li>
      </ul>
    </div>
    <el-card style="margin-top:30px">
      <!-- 自动回复开关 -->
      <div class="title_box">
        <div class="title_name_box">
          <div>自动回复</div>
          <el-switch
            v-model="value"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </div>
        <div class="title_desc">
          通过编辑内容或关键词规则，快速进行自动回复设置。如具备开发能力，可更灵活地使用该功能。
          关闭自动回复之后，将立即对所有用户生效。
        </div>
      </div>

      <!-- 关键词回复模块 -->
      <template v-if="tabs == 0">
        <!-- 搜索,添加回复 -->
        <div class="search_box">
          <el-input
            placeholder="搜索关键词/规则名称"
            suffix-icon="el-icon-search"
            v-model="input1"
            class="search_input"
          >
          </el-input>
          <el-button type="primary" class="add">添加回复</el-button>
        </div>
        <!-- 回复关键词表格区域 -->
        <el-table
          :data="dubbWorksList"
          stripe
          class="table"
          ref="userTable.multipleSelection"
          header-cell-class-name="table-header"
          border
        >
          <el-table-column
            type="selection"
            width="55"
            align="center"
          ></el-table-column>

          <el-table-column
            prop="id"
            label="ID"
            width="55"
            align="center"
          ></el-table-column>

          <el-table-column
            prop="title"
            label="规则名称"
            align="center"
          ></el-table-column>
          <el-table-column
            label="关键词"
            prop="views"
            align="center"
          ></el-table-column>

          <el-table-column
            label="回复内容"
            prop="reviewer"
            align="center"
          ></el-table-column>

          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button
                type="success"
                size="small"
                icon="el-icon-info"
                circle
              ></el-button>
              <el-button
                type="primary"
                icon="el-icon-edit"
                circle
                size="small"
              ></el-button>
              <el-button
                type="danger"
                icon="el-icon-delete"
                circle
                size="small"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <!-- 收到的消息回复模块 -->
      <template v-else-if="tabs == 1" style="">
        <el-form :model="addReadForm" ref="addForm" style="margin-top:30px">
          <el-form-item el-form-item>
            <quill-editor v-model="addReadForm.content" ref="myQuillEditor">
            </quill-editor>
          </el-form-item>
          <el-form-item el-form-item>
            <el-button type="primary" style="width:100px">保存</el-button>
            <el-button style="width:100px">删除回复</el-button>
          </el-form-item>
        </el-form>
      </template>
      <!-- 被关注回复模块 -->
      <template v-else-if="tabs == 2" style="">
        <el-form :model="addReadForm" ref="addForm" style="margin-top:30px">
          <el-form-item el-form-item>
            <quill-editor
              v-model="addReadForm.content"
              style="height:100%"
              ref="myQuillEditor"
            >
            </quill-editor>
          </el-form-item>
          <el-form-item el-form-item>
            <el-button type="primary" style="width:100px">保存</el-button>
            <el-button style="width:100px">删除回复</el-button>
          </el-form-item>
        </el-form>
      </template>
    </el-card>
  </div>
</template>

<script>
//引入富文本组件
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
export default {
  components: {
    quillEditor
  },
  data() {
    return {
      tab: [
        { name: "关键词回复", tabIndex: 0 },
        { name: "收到的消息回复", tabIndex: 1 },
        { name: "被关注回复", tabIndex: 2 }
      ],
      tabs: 0,
      value: true,
      input1: "",
      addReadForm: {
        content: ""
      },
      dubbWorksList:[]
    };
  },
  methods: {
    // 顶部tab栏切换
    toggletabs(i, elm) {
      this.tabs = i;
    }
  }
};
</script>

<style lang="less" scoped>
li {
  list-style: none;
  cursor: pointer;
}
.headers {
  /* display: flex; */
  border-bottom: 1px solid #ddd;
  line-height: 50px;
  width: 100%;
  height: 50px;
  padding: 0 30px;
  ul {
    width: 100%;
    li {
      /* flex: 2; */
      height: 50px;
      float: left;
      margin-right: 60px;
    }
  }
  .selected {
    color: #38a28a;
    border-bottom: 1px solid #38a28a;
  }
}

.title_box {
  .title_name_box {
    display: flex;
    justify-content: space-between;
  }
  .title_desc {
    margin-top: 10px;
    width: 50%;
    line-height: 20px;
    color: #b1b2b3;
  }
}

.search_box {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #ddd;
  margin-bottom: 20px;
  .search_input {
    width: 15%;
  }
}
::v-deep .ql-editor {
  min-height: 400px;
}
</style>
